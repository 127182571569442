<template>
  <div
    :class="['background-image-container', additionalClasses]"
    :style="{ backgroundImage: `url('${currentBgImage}')` }"
  >
    <div
      v-if="isLoading || hasError"
      class="sk-center sk-chase bg-loading-indicator"
    >
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
    </div>
    <div v-if="hasError && retryCount >= maxRetries" class="error-indicator">
      <img :src="placeholder" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { watchEffect } from "vue"
  import { useBackgroundImageLoader } from "@/composables/useBackgroundImageLoader.ts"

  const props = defineProps({
    imageUrl: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "/images/transparent.gif",
    },
    retryInterval: {
      type: Number,
      default: 3000,
    },
    maxRetries: {
      type: Number,
      default: 99999,
    },
    additionalClasses: {
      type: String,
      default: "",
    },
  })

  const emit = defineEmits<{
    (e: "max-retries-exceeded", imageUrl: string): void
  }>()

  const { currentBgImage, hasError, isLoading, retryCount } =
    useBackgroundImageLoader(props)

  watchEffect(() => {
    if (hasError.value && retryCount.value >= props.maxRetries) {
      emit("max-retries-exceeded", props.imageUrl)
    }
  })
</script>

<style scoped>
  .background-image-container {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
  }

  .loading-indicator,
  .error-indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    text-align: center;
  }

  .bg-loading-indicator {
    --sk-color: white;
    --sk-size: 60%;
  }

  .error-indicator {
  }
</style>
