<template>
  <USelectMenu
    v-model="selected"
    by="username"
    :loading="loading"
    :placeholder="placeholder"
    :searchable="search"
    option-attribute="username"
    multiple
    variant="none"
    :ui="selectButtonStyles"
    :uiMenu="uiMenuStyles"
  >
    <template #label>
      <div
        v-if="selected.length"
        class="flex flex-row flex-wrap items-center gap-2"
      >
        <div
          v-for="user in selected"
          :key="user.id"
          class="flex flex-row items-center gap-1 bg-cool-200 rounded-full py-1 px-2 dark:bg-[#1b2022]"
        >
          <span>@{{ user.username }}</span>
          <font-awesome-icon
            @click.prevent="removeUserFromList(user)"
            icon="fa-light fa-circle-xmark"
            class="text-primary-600"
          />
        </div>

        <div class="flex flex-row items-center gap-1">
          <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
          <span>Search Users</span>
        </div>
      </div>
      <span v-else>Select users</span>
    </template>

    <template #option="{ option: user }">
      <div class="w-full flex flex-row items-center gap-1">
        <Avatar size="3xs" :src="user.signedPhotoThumbUrl" />
        <span>{{ user.displayName }}</span>
        <span class="text-cool-400 text-xs">(@{{ user.username }})</span>
      </div>
    </template>
  </USelectMenu>
</template>

<script setup>
  const props = defineProps({
    modelValue: {
      type: Array,
      default: [],
    },
    placeholder: String,
    userSearchUrl: String,
    includeSelf: Boolean,
  })

  const loading = ref(false)
  const selected = ref(props.modelValue)
  const emit = defineEmits(["update:modelValue"])

  watch(
    () => props.modelValue,
    (newValue) => {
      if (newValue !== selected.value) {
        selected.value = newValue
      }
    },
  )

  watch(selected, (newValue, oldValue) => {
    if (newValue !== oldValue) {
      emit("update:modelValue", newValue)
    }
  })

  const config = useRuntimeConfig()
  const search = async (query) => {
    if (query && query.length > 2) {
      loading.value = true
      const response = await $api(
        `${config.public.API_URL}${props.userSearchUrl}`,
        {
          params: {
            include_self: props.includeSelf,
            query: query,
          },
        },
      )
      loading.value = false
      return response.items
    } else {
      return []
    }
  }

  const removeUserFromList = (user) => {
    selected.value = selected.value.filter((u) => u.username !== user.username)
  }

  const selectButtonStyles = {
    rounded: "lg",
    size: { xl: "text-sm" },
    padding: { xl: "px-3 py-8" },
    placeholder: "text-gray-400 dark:text-blue-500",
    variant: {
      none:
        "font-semibold shadow-sm ring-1 ring-[#384247] " +
        "ring-inset ring-[#E4E4E4] dark:ring-[#384247]" +
        "focus:ring-[#f26998] dark:focus:ring-2 dark:focus:ring-[#f26998] " +
        "focus-visible:ring-",
    },
  }

  const uiMenuStyles = {
    background: "bg-white dark:bg-[#1B2022] dark:text-white",
    input:
      "block w-[calc(100%+0.5rem)] focus:ring-transparent text-sm px-3 py-1.5 text-gray-700 dark:text-white bg-white dark:bg-[#1B2022] border-0 border-b border-gray-200 dark:border-gray-700 sticky -top-1 -mt-1 mb-1 -mx-1 z-10 placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none",
    option: {
      active: "bg-gray-200 dark:bg-[#2B3236] dark:text-white",
    },
  }
</script>
