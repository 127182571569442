<!-- components/VideoPlayer.vue -->
<template>
  <video
    ref="videoRef"
    class="w-full"
    playsinline
    autoplay
    :loop="loop"
    :muted="muted"
    :controls="showControls"
    @ended="isPlaying = false"
    @mouseenter="showControls = true"
    @mouseleave="showControls = false"
    @touchstart="handleInteraction"
    @error="onError"
    @loadeddata="onLoadedData"
  >
    <source :src="src" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</template>

<script setup lang="ts">
  import { ref, watch, onMounted, onUnmounted } from "vue"

  const props = defineProps({
    src: {
      type: String,
      required: true,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    muted: {
      type: Boolean,
      default: false,
    },
  })

  const emit = defineEmits(["error", "loaded"])

  const videoRef = ref<HTMLVideoElement | null>(null)
  const showControls = ref(false)
  const isPlaying = ref(false)

  // Toggle play/pause
  const togglePlay = () => {
    if (videoRef.value) {
      if (videoRef.value.paused) {
        videoRef.value.play()
        isPlaying.value = true
      } else {
        videoRef.value.pause()
        isPlaying.value = false
      }
    }
  }

  // Handle user interaction to show controls
  const handleInteraction = () => {
    showControls.value = true
    // Hide controls after 3 seconds of inactivity
    setTimeout(() => {
      showControls.value = false
    }, 3000)
  }

  // Emit error event when video fails to load
  const onError = (event: Event) => {
    emit("error", event)
  }

  // Emit loaded event when video data is loaded
  const onLoadedData = () => {
    emit("loaded")
  }

  onMounted(() => {
    if (videoRef.value) {
      videoRef.value.addEventListener("ended", () => {
        isPlaying.value = false
      })
    }
  })

  // Watch for changes in src to reload the video
  watch(
    () => props.src,
    (newSrc) => {
      if (videoRef.value) {
        videoRef.value.load()
      }
    },
  )

  onUnmounted(() => {
    if (videoRef.value) {
      videoRef.value.removeEventListener("ended", () => {
        isPlaying.value = false
      })
    }
  })
</script>

<style scoped>
  video {
    width: 100%;
    height: auto;
  }
</style>
