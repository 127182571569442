<template>
  <!-- Loading Indicator -->
  <div
    v-if="isLoading || hasError"
    class="sk-center sk-chase bg-loading-indicator"
  >
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
  </div>
  <!-- Avatar Image -->
  <UAvatar v-else :size="size" :src="currentBgImage" />
</template>

<script setup lang="ts">
  import { computed, watchEffect } from "vue"
  import { useBackgroundImageLoader } from "@/composables/useBackgroundImageLoader"

  // Define component props
  const props = defineProps({
    showIndicator: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "md",
    },
    src: {
      type: String,
      default:
        "https://favoritely-assets.s3.us-west-1.amazonaws.com/staging/assets/photo_placeholders/250x250.png",
    },
  })

  // Define component emits
  const emit = defineEmits<{
    (e: "max-retries-exceeded", src: string): void
  }>()

  const placeholderSrc = "/images/transparent.gif" // Placeholder image path

  // Determine if the source is a video
  const urlIsVideo = computed(() => {
    return props.src?.toLowerCase().endsWith(".mp4")
  })

  // Size classes mapping
  const sizeMap = {
    "3xs": "h-[4px] min-w-[4px] text-[4px] p-px",
    "2xs": "h-[5px] min-w-[5px] text-[5px] p-px",
    xs: "h-6 min-w-[0.375rem] text-[6px] p-px",
    sm: "h-8 min-w-[0.5rem] text-[7px] p-0.5",
    md: "h-12 min-w-[0.625rem] text-[8px] p-0.5",
    lg: "h-14 min-w-[0.75rem] text-[10px] p-0.5",
    xl: "h-16 min-w-[0.875rem] text-[11px] p-1",
    "2xl": "h-20 min-w-[1rem] text-[12px] p-1",
    "3xl": "h-24 text-[14px] p-1",
  }

  // Compute the size classes based on the `size` prop
  const sizeClasses = computed(() => {
    return sizeMap[props.size]
  })

  // Initialize the composable
  const { currentBgImage, hasError, isLoading, retryCount } =
    useBackgroundImageLoader({
      imageUrl: props.src,
      placeholder: placeholderSrc,
      retryInterval: 3000, // Retry every 3 seconds
      maxRetries: 5, // Set a reasonable default
    })

  // Watch for maxRetries exceeded to emit an event
  watchEffect(() => {
    if (hasError.value && retryCount.value >= 5) {
      // maxRetries=5
      emit("max-retries-exceeded", props.src)
    }
  })
</script>

<style scoped>
  /* Optional: Adjust the styles as needed */
  .bg-loading-indicator {
    --sk-color: white;
    border: 0px transparent;
  }
</style>
