<template>
  <div
    class="sm:order-2 order-1 flex flex-col gap-3 p-3 rounded-lg z-10 min-w-[375px] mt-8"
  >
    <p class="font-semibold">Tag the other people who appear in your upload.</p>

    <UFormGroup>
      <template #description>
        <p v-if="numberOfFacesToTag > 1">
          {{ numberOfFacesToTag - creatorsToTag.length }}
          {{
            numberOfFacesToTag - creatorsToTag.length > 1 ? "people" : "person"
          }}
          left to tag.
        </p>
      </template>
      <template #help>
        <p class="text-xs pt-2">
          For any questions, please refer to our support article on
          <a
            href="https://support.favoritely.com/creators.html#uploaded-media-content"
            target="_blank"
            class="underline"
          >
            uploaded content
          </a>
          . You can also email us at
          <a href="mailto:support@favoritely.com">support@favoritely.com</a>
          .
        </p>
      </template>
      <UserSearch
        v-model="creatorsToTag"
        user-search-url="/api/users/search"
        include-self="true"
        class="py-1"
      />
    </UFormGroup>
  </div>
</template>

<script setup>
  const props = defineProps({
    media: {
      type: Object,
      required: true,
    },
  })

  const config = useRuntimeConfig()
  const creatorsToTag = ref([])
  const numberOfFacesToTag = ref(props.media.numberOfFaces - 1)
  const emit = defineEmits(["update-medium"])

  watch(
    () => creatorsToTag.value,
    (newValue, oldValue) => {
      if (newValue.length >= numberOfFacesToTag.value) {
        tagUsers()
      }
    },
  )

  const toast = useToast()
  const tagUsers = async () => {
    try {
      const response = await $api(
        `${config.public.API_URL}/api/media/${props.media.id}/tag_creators`,
        {
          method: "POST",
          body: {
            media: {
              tagged_usernames: creatorsToTag.value.map((c) => c.username),
            },
          },
        },
      )
      toast.add({
        title: "All users were tagged!",
        description: "Any posts and/or DM's attached are now viewable.",
        color: "green",
      })
      emit("update-medium", response)
      emit("close")
    } catch (error) {
      console.log(error)
    }
  }
</script>

<style scoped lang="scss">
  .wrapper {
    @apply fixed top-0 left-0 h-screen w-screen z-10;
  }

  .overlay {
    @apply fixed inset-0 transition-opacity bg-gray-200/75 dark:bg-gray-800/75 z-10;
  }

  .position-center {
    @apply w-screen h-screen flex flex-col justify-center items-center;
  }

  .position-top {
    @apply absolute top-0 w-full flex flex-col justify-start items-center p-4;
  }

  .chip {
    @apply py-2 px-3 bg-gray-200 rounded-full flex items-center gap-1;
    @apply dark:bg-[#2b3236];
  }

  .btn-dismiss {
    @apply rounded-full p-1 transition-colors cursor-pointer;
    @apply bg-gray-300 dark:bg-gray-900 hover:bg-primary-600 dark:hover:bg-primary-500;
    @apply hover:bg-primary-600 hover:text-white;
  }
</style>
